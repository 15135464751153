import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Пропозиції | Тенісні корти Cleverus Land
			</title>
			<meta name={"description"} content={"вдосконаліть свою гру з допомогою Cleverus Land"} />
			<meta property={"og:title"} content={"Пропозиції | Тенісні корти Cleverus Land"} />
			<meta property={"og:description"} content={"вдосконаліть свою гру з допомогою Cleverus Land"} />
			<meta property={"og:image"} content={"https://cleverusland.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://cleverusland.com/img/935231-446.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://cleverusland.com/img/935231-446.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://cleverusland.com/img/935231-446.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://cleverusland.com/img/935231-446.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://cleverusland.com/img/935231-446.png"} />
			<meta name={"msapplication-TileImage"} content={"https://cleverusland.com/img/935231-446.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 120px 0" sm-padding="60px 0 60px 0" quarkly-title="Cards-8" background="#e9f3ff">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="flex-start"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 70px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 10px 0px"
					color="--dark"
					font="--headline2"
					text-align="left"
					sm-font="normal 700 28px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					width="70%"
					lg-width="100%"
				>
					Комплексні послуги з тенісу
				</Text>
							<Text
								margin="0px 0px 0px 0px"
								color="--dark"
								font="--lead"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
							>
								У Cleverus Land Tennis Courts ми виходимо за рамки надання чудових кортів – ми пропонуємо повний спектр послуг, розроблених для покращення ваших вражень від тенісу. Незалежно від того, чи шукаєте ви тренування найвищого рівня, оренду спорядження чи організацію подій, наш заклад обладнаний для задоволення всіх ваших тенісних потреб.
							</Text>
			</Box>
			<Box
				display="grid"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="16px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px">
					<Box
						padding="32px 32px 32px 32px"
						background="#fff"
						border-width="1px"
						border-style="solid"
						border-radius="24px"
						border-color="--color-darkL2"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						min-height="300px"
						justify-content="space-between"
						margin="0px 0px 16px 0px"
						md-min-height="350px"
					>
						<Box>
							<Text
								margin="0px 0px 20px 0px"
								color="--dark"
								font="--headline3"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								width="100%"
							>
								Персоналізовані навчальні програми
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								color="--dark"
								font="--lead"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								letter-spacing="-0.5px"
							>
								індивідуальні заняття, розроблені відповідно до ваших конкретних потреб і цілей.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						padding="32px 32px 32px 32px"
						background="#fff"
						border-width="1px"
						border-style="solid"
						border-radius="24px"
						border-color="--color-darkL2"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						min-height="300px"
						justify-content="space-between"
						margin="0px 0px 16px 0px"
						md-min-height="350px"
					>
						<Box md-width="100%">
							<Text
								margin="0px 0px 20px 0px"
								color="--dark"
								font="--headline3"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								width="100%"
							>
								Уроки для початківців
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								color="--dark"
								font="--lead"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								letter-spacing="-0.5px"
							>
								для новачків у тенісі, зосереджуючись на основах.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						padding="32px 32px 32px 32px"
						background="#fff"
						border-width="1px"
						border-style="solid"
						border-radius="24px"
						border-color="--color-darkL2"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						min-height="300px"
						justify-content="space-between"
						margin="0px 0px 16px 0px"
						md-min-height="350px"
					>
						<Box md-width="100%">
							<Text
								margin="0px 0px 20px 0px"
								color="--dark"
								font="--headline3"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								width="100%"
							>
								Просунутий тренінг
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								color="--dark"
								font="--lead"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								letter-spacing="-0.5px"
							>
								для професійних гравців, які прагнуть вдосконалити свою гру.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						padding="32px 32px 32px 32px"
						background="#fff"
						border-width="1px"
						border-style="solid"
						border-radius="24px"
						border-color="--color-darkL2"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						min-height="300px"
						justify-content="space-between"
						margin="0px 0px 16px 0px"
						md-min-height="350px"
					>
						<Box>
							<Text
								margin="0px 0px 20px 0px"
								color="--dark"
								font="--headline3"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								width="100%"
							>
								Групові заняття
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								color="--dark"
								font="--lead"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								letter-spacing="-0.5px"
							>
								навчайтеся та практикуйтеся в сприятливому груповому середовищі.
							</Text>
						</Box>
					</Box>
				</Box>

				<Box min-width="100px" min-height="100px">
					<Box
						padding="32px 32px 32px 32px"
						background="#fff"
						border-width="1px"
						border-style="solid"
						border-radius="24px"
						border-color="--color-darkL2"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						min-height="300px"
						justify-content="space-between"
						margin="0px 0px 16px 0px"
						md-min-height="350px"
					>
						<Box md-width="100%">
							<Text
								margin="0px 0px 20px 0px"
								color="--dark"
								font="--headline3"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								width="100%"
							>
								Групові заняття для дорослих
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								color="--dark"
								font="--lead"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								letter-spacing="-0.5px"
							>
								ідеально підходить для рекреаційних гравців.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						padding="32px 32px 32px 32px"
						background="#fff"
						border-width="1px"
						border-style="solid"
						border-radius="24px"
						border-color="--color-darkL2"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						min-height="300px"
						justify-content="space-between"
						margin="0px 0px 16px 0px"
						md-min-height="350px"
					>
						<Box md-width="100%">
							<Text
								margin="0px 0px 20px 0px"
								color="--dark"
								font="--headline3"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								width="100%"
							>
								Молодіжні групові заняття
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								color="--dark"
								font="--lead"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								letter-spacing="-0.5px"
							>
								призначені для молодших гравців, щоб розвивати свої навички.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						padding="32px 32px 32px 32px"
						background="#fff"
						border-width="1px"
						border-style="solid"
						border-radius="24px"
						border-color="--color-darkL2"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						min-height="300px"
						justify-content="space-between"
						margin="0px 0px 16px 0px"
						md-min-height="350px"
					>
						<Box md-width="100%">
							<Text
								margin="0px 0px 20px 0px"
								color="--dark"
								font="--headline3"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								width="100%"
							>
								Сімейні уроки
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								color="--dark"
								font="--lead"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								letter-spacing="-0.5px"
							>
								розвага для всієї родини, незалежно від рівня навичок.

							</Text>
						</Box>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						padding="32px 32px 32px 32px"
						background="#fff"
						border-width="1px"
						border-style="solid"
						border-radius="24px"
						border-color="--color-darkL2"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						min-height="300px"
						justify-content="space-between"
						margin="0px 0px 16px 0px"
						md-min-height="350px"
					>
						<Box md-width="100%">
							<Text
								margin="0px 0px 20px 0px"
								color="--dark"
								font="--headline3"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								width="100%"
							>
								Оренда та продаж ракеток
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								color="--dark"
								font="--lead"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								letter-spacing="-0.5px"
							>
								високоякісні ракетки можна взяти напрокат або купити.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-3" background="#e9f3ff">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 20px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						height="400px"
						display="flex"
						align-items="flex-end"
						padding="0px 15px 35px 15px"
						background="linear-gradient(180deg,rgba(4, 8, 12, 0.05) 0%, rgba(4, 8, 12, 0.45) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://cleverusland.com/img/7.jpg) 50% 0% /cover repeat scroll padding-box"
						color="--light"
						lg-height="300px"
						md-height="400px"
					>
						Організація турнірів
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 20px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						height="400px"
						display="flex"
						align-items="flex-end"
						padding="0px 15px 35px 15px"
						background="linear-gradient(180deg,rgba(4, 8, 12, 0.05) 0%, rgba(4, 8, 12, 0.45) 100%)  0 0 no-repeat,rgba(0, 0, 0, 0) url(https://cleverusland.com/img/8.jpg) 50% 0% /cover repeat scroll padding-box"
						color="--light"
						lg-height="300px"
						md-height="400px"
					>
						 Благодійні заходи
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 20px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						height="400px"
						display="flex"
						align-items="flex-end"
						padding="0px 15px 35px 15px"
						background="linear-gradient(180deg,rgba(4, 8, 12, 0.05) 0%, rgba(4, 8, 12, 0.45) 100%)  0 0 no-repeat,rgba(0, 0, 0, 0) url(https://cleverusland.com/img/9.jpg) 50% 0% /cover repeat scroll padding-box"
						color="--light"
						lg-height="300px"
						md-height="400px"
					>
						Особливі події
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" background="#e9f3ff" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://cleverusland.com/img/10.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://cleverusland.com/img/11.jpg"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Відчуйте найкраще в тенісі на тенісних кортах Cleverus Land. Забронюйте сеанс сьогодні та виведіть свою гру на новий рівень
				</Text>
			</Box>
		</Section>
		<Components.Contacts />
		<Components.FooterMenu />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});